<template>
  <div class="liveLottery">
    <div v-title data-title="新人七日任务"></div>

    <!-- <van-nav-bar
      title="真人闯关"
      left-arrow
      :fixed="true"
      @click-left="gotoback"
    /> -->

    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-newpeople.jpg`" />
    </div>

    <div class="changebox">
      <div
        class="changebox-item"
        :class="timex1 == '已结束' || datalist.ret == 0 ? 'onchangetab' : ''"
        @click="Submit(1)"
      >
        第一天
      </div>
      <div
        class="changebox-item"
        :class="timex2 == '已结束' || datalist.ret == 0 ? 'onchangetab' : ''"
        @click="Submit(2)"
      >
        第二天
      </div>
      <div
        class="changebox-item"
        :class="timex3 == '已结束' || datalist.ret == 0 ? 'onchangetab' : ''"
        @click="Submit(3)"
      >
        第三天
      </div>
      <div
        class="changebox-item"
        :class="timex4 == '已结束' || datalist.ret == 0 ? 'onchangetab' : ''"
        @click="Submit(4)"
      >
        第四天
      </div>
      <div
        class="changebox-item"
        :class="timex5 == '已结束' || datalist.ret == 0 ? 'onchangetab' : ''"
        @click="Submit(5)"
      >
        第五天
      </div>
      <div
        class="changebox-item"
        :class="timex6 == '已结束' || datalist.ret == 0 ? 'onchangetab' : ''"
        @click="Submit(6)"
      >
        第六天
      </div>
      <div
        class="changebox-item"
        :class="timex7 == '已结束' || datalist.ret == 0 ? 'onchangetab' : ''"
        @click="Submit(7)"
      >
        第七天
      </div>
      <div
        class="changebox-item"
        :class="datalist.ret == 0 ? 'onchangetab' : ''"
        @click="Submit(8)"
      >
        最后奖励
      </div>

      <div
        style="width:100%;font-size: 14px;text-align: center;padding: 10px 0;color:red"
        v-if="datalist.ret == 0"
      >
        <img src="../assets/img/pcbb/warning.png" width="100" />
        <p>必须有一笔存款才能激活新人七日活动</p>
      </div>
    </div>

    <!-- 第一天 -->
    <div class="newpepele-item" id="page1" v-if="datalist.list">
      <div class="newpepele-title">
        <img
          src="https://sabbont.gz.bcebos.com/pc/img/discount/conditionact1.png"
        />
        第一天
      </div>

      <div class="newpepele-img">
        <div class="newpepele-box">
          <img :src="`${imgUrl}pcbb/newperson1.png`" />
          <img
            class="circle-btn circle-btn1"
            :src="`${imgUrl}pcbb/circle-btn.png`"
            :class="changrebagindex == 1 ? 'rotateing' : ''"
            @click="changetab(1)"
          />
          <div class="circle-money circle-money1">
            18彩金
          </div>
          <div
            class="circle-text circle-text1"
            @click="changetab(1)"
            v-if="
              datalist.list[0][0] == 1 &&
                datalist.list[0][1] == 1 &&
                timex1 != '已结束' &&
                timex1 != '未开始' &&
                datalist.ztlist[0] == 0
            "
          >
            可领取
          </div>
          <div class="circle-text circle-text1" v-else-if="timex1 == '未开始'">
            未开始
          </div>
          <div
            class="circle-text circle-text1"
            v-else-if="datalist.ztlist[0] == 1"
          >
            已领取
          </div>
          <div class="circle-text circle-text1" v-else-if="timex1 == '已结束'">
            已结束
          </div>
          <div class="circle-text circle-text1" v-else>任务未完成</div>
        </div>
        <div class="time">
          剩余时间:
          <div style="color:red" v-if="timex1 == '已结束'">已结束</div>
          <div style="color:green" v-else-if="timex1 == '未开始'">未开始</div>
          <van-count-down v-else :time="timex1" @finish="changefinish(1)" />
        </div>
      </div>
      <div class="newpepele-btns">
        <!-- <div class="newpepele-btns-item" :class="datalist.list[0][0] == 1 ? 'onchangetab' : ''">
          身份证绑定
        </div> -->
        <div
          class="newpepele-btns-item"
          :class="datalist.list[0][0] == 1 ? 'onchangetab' : ''"
        >
          绑定手机号
        </div>
        <div
          class="newpepele-btns-item"
          :class="datalist.list[0][1] == 1 ? 'onchangetab' : ''"
        >
          银行卡绑定
        </div>
      </div>
      <div class="newpepele-regulation">
        <div class="regulation-title">任务拟定:</div>
        <div class="regulation-content">
          <!-- <p>1.完成姓名身份证绑定</p> -->
          <p>1.完成手机号绑定</p>
          <p>2.完成银行卡绑定</p>
        </div>
        <div class="regulation-title">任务奖励:</div>
        <div class="regulation-content">
          完成本日任务送 <span style="color:#2d70fd">18</span> 彩金
        </div>
      </div>
    </div>

    <!-- 第二天 -->
    <div class="newpepele-item" id="page2" v-if="datalist.list">
      <div class="newpepele-title">
        <img
          src="https://sabbont.gz.bcebos.com/pc/img/discount/conditionact1.png"
        />
        第二天
      </div>

      <div class="newpepele-img">
        <div class="newpepele-box">
          <img :src="`${imgUrl}pcbb/newperson2.png`" />
          <img
            class="circle-btn circle-btn5"
            :src="`${imgUrl}pcbb/circle-btn.png`"
            :class="changrebagindex == 2 ? 'rotateing' : ''"
            @click="changetab(2)"
          />
          <div class="circle-money circle-money2">
            38彩金
          </div>
          <div
            class="circle-text circle-text2"
            @click="changetab(2)"
            v-if="
              datalist.list[1][0] == 1 &&
                datalist.list[1][1] == 1 &&
                datalist.list[1][2] == 1 &&
                timex2 != '已结束' &&
                timex2 != '未开始' &&
                datalist.ztlist[1] == 0
            "
          >
            可领取
          </div>
          <div class="circle-text circle-text2" v-else-if="timex2 == '未开始'">
            未开始
          </div>
          <div
            class="circle-text circle-text2"
            v-else-if="datalist.ztlist[1] == 1"
          >
            已领取
          </div>
          <div class="circle-text circle-text2" v-else-if="timex2 == '已结束'">
            已结束
          </div>
          <div class="circle-text circle-text2" v-else>
            任务未完成
          </div>
        </div>
        <div class="time">
          剩余时间:
          <div style="color:red" v-if="timex2 == '已结束'">已结束</div>
          <div style="color:green" v-else-if="timex2 == '未开始'">未开始</div>
          <van-count-down v-else :time="timex2" @finish="changefinish(7)" />
        </div>
      </div>
      <div class="newpepele-btns">
        <div
          class="newpepele-btns-item"
          :class="datalist.list[1][0] == 1 ? 'onchangetab' : ''"
        >
          领取体育存送
        </div>
        <div
          class="newpepele-btns-item"
          :class="datalist.list[1][1] == 1 ? 'onchangetab' : ''"
        >
          参与1笔体育投注
        </div>
        <div
          class="newpepele-btns-item"
          :class="datalist.list[1][2] == 1 ? 'onchangetab' : ''"
        >
          体育流水≥500
        </div>
      </div>
      <div class="newpepele-regulation">
        <div class="regulation-title">任务拟定:</div>
        <div class="regulation-content">
          <p>1.领取体育存送</p>
          <p>2.本日参与1笔体育投注</p>
          <p>3.本日体育流水≥500</p>
        </div>
        <div class="regulation-title">任务奖励:</div>
        <div class="regulation-content">
          完成本日任务送 <span style="color:#2d70fd">38</span> 彩金
        </div>
      </div>
    </div>

    <!-- 第三天 -->
    <div class="newpepele-item" id="page3" v-if="datalist.list">
      <div class="newpepele-title">
        <img
          src="	https://sabbont.gz.bcebos.com/pc/img/discount/conditionact1.png"
        />
        第三天
      </div>

      <div class="newpepele-img">
        <div class="newpepele-box">
          <img :src="`${imgUrl}pcbb/newperson3.png`" />
          <img
            class="circle-btn circle-btn1"
            :src="`${imgUrl}pcbb/circle-btn.png`"
            :class="changrebagindex == 3 ? 'rotateing' : ''"
            @click="changetab(3)"
          />
          <div class="circle-money circle-money6">
            38彩金
          </div>
          <div
            class="circle-text circle-text3"
            @click="changetab(3)"
            v-if="
              datalist.list[2][0] == 1 &&
                datalist.list[2][1] == 1 &&
                datalist.list[2][2] == 1 &&
                timex3 != '已结束' &&
                timex3 != '未开始' &&
                datalist.ztlist[2] == 0
            "
          >
            可领取
          </div>
          <div class="circle-text circle-text3" v-else-if="timex3 == '未开始'">
            未开始
          </div>
          <div
            class="circle-text circle-text3"
            v-else-if="datalist.ztlist[2] == 1"
          >
            已领取
          </div>
          <div class="circle-text circle-text3" v-else-if="timex3 == '已结束'">
            已结束
          </div>
          <div class="circle-text circle-text3" v-else>
            任务未完成
          </div>
        </div>
        <div class="time">
          剩余时间:
          <div style="color:red" v-if="timex3 == '已结束'">已结束</div>
          <div style="color:green" v-else-if="timex3 == '未开始'">未开始</div>
          <van-count-down v-else :time="timex3" @finish="changefinish(3)" />
        </div>
      </div>
      <div class="newpepele-btns">
        <div
          class="newpepele-btns-item"
          :class="datalist.list[2][0] == 1 ? 'onchangetab' : ''"
        >
          领取真人存送
        </div>
        <div
          class="newpepele-btns-item"
          :class="datalist.list[2][1] == 1 ? 'onchangetab' : ''"
        >
          参与1笔真人投注
        </div>
        <div
          class="newpepele-btns-item"
          :class="datalist.list[2][2] == 1 ? 'onchangetab' : ''"
        >
          真人流水≥1000
        </div>
      </div>
      <div class="newpepele-regulation">
        <div class="regulation-title">任务拟定:</div>
        <div class="regulation-content">
          <p>1.领取真人存送</p>
          <p>2.本日参与1笔真人投注</p>
          <p>3.本日真人流水≥1000</p>
        </div>
        <div class="regulation-title">任务奖励:</div>
        <div class="regulation-content">
          完成本日任务送 <span style="color:#2d70fd">38</span> 彩金
        </div>
      </div>
    </div>

    <!-- 第四天 -->
    <div class="newpepele-item" id="page4" v-if="datalist.list">
      <div class="newpepele-title">
        <img
          src="	https://sabbont.gz.bcebos.com/pc/img/discount/conditionact1.png"
        />
        第四天
      </div>

      <div class="newpepele-img">
        <div class="newpepele-box">
          <img :src="`${imgUrl}pcbb/newperson4.png`" />
          <img
            class="circle-btn circle-btn2"
            :src="`${imgUrl}pcbb/circle-btn.png`"
            :class="changrebagindex == 4 ? 'rotateing' : ''"
            @click="changetab(4)"
          />
          <div class="circle-money circle-money3">
            38彩金
          </div>
          <div
            class="circle-text circle-text3"
            @click="changetab(4)"
            v-if="
              datalist.list[3][0] == 1 &&
                timex4 != '已结束' &&
                timex4 != '未开始' &&
                datalist.ztlist[3] == 0
            "
          >
            可领取
          </div>
          <div class="circle-text circle-text3" v-else-if="timex4 == '未开始'">
            未开始
          </div>
          <div
            class="circle-text circle-text3"
            v-else-if="datalist.ztlist[3] == 1"
          >
            已领取
          </div>
          <div class="circle-text circle-text3" v-else-if="timex4 == '已结束'">
            已结束
          </div>
          <div class="circle-text circle-text3" v-else>
            任务未完成
          </div>
        </div>
        <div class="time">
          剩余时间:
          <div style="color:red" v-if="timex4 == '已结束'">已结束</div>
          <div style="color:green" v-else-if="timex4 == '未开始'">未开始</div>
          <van-count-down v-else :time="timex4" @finish="changefinish(7)" />
        </div>
      </div>
      <div class="newpepele-btns">
        <div
          class="newpepele-btns-item"
          :class="datalist.list[3][0] == 1 ? 'onchangetab' : ''"
        >
          在直播体育进行1次投注
        </div>
        <!-- <div class="newpepele-btns-item" :class="datalist.list[3][1] == 1 ? 'onchangetab' : ''">
          在直播体育发送任意金额1次礼物
        </div> -->
      </div>
      <div class="newpepele-regulation">
        <div class="regulation-title">任务拟定:</div>
        <div class="regulation-content">
          <p>1.在直播体育进行1次投注</p>
          <!-- <p>2.在直播体育发送任意金额1次礼物</p> -->
        </div>
        <div class="regulation-title">任务奖励:</div>
        <div class="regulation-content">
          完成本日任务送 <span style="color:#2d70fd">38</span> 彩金
        </div>
      </div>
    </div>

    <!-- 第五天 -->
    <div class="newpepele-item" id="page5" v-if="datalist.list">
      <div class="newpepele-title">
        <img
          src="	https://sabbont.gz.bcebos.com/pc/img/discount/conditionact1.png"
        />
        第五天
      </div>

      <div class="newpepele-img">
        <div class="newpepele-box">
          <img :src="`${imgUrl}pcbb/newperson5.png`" />
          <img
            class="circle-btn circle-btn3"
            :src="`${imgUrl}pcbb/circle-btn.png`"
            :class="changrebagindex == 5 ? 'rotateing' : ''"
            @click="changetab(5)"
          />
          <div class="circle-money circle-money5">
            38彩金
          </div>

          <div
            class="circle-text circle-text5"
            @click="changetab(5)"
            v-if="
              datalist.list[4][0] == 1 &&
                datalist.list[4][1] == 1 &&
                timex5 != '已结束' &&
                timex5 != '未开始' &&
                datalist.ztlist[4] == 0
            "
          >
            可领取
          </div>
          <div class="circle-text circle-text5" v-else-if="timex5 == '未开始'">
            未开始
          </div>
          <div
            class="circle-text circle-text5"
            v-else-if="datalist.ztlist[4] == 1"
          >
            已领取
          </div>
          <div class="circle-text circle-text5" v-else-if="timex5 == '已结束'">
            已结束
          </div>
          <div class="circle-text circle-text5" v-else>
            任务未完成
          </div>
        </div>
        <div class="time">
          剩余时间:
          <div style="color:red" v-if="timex5 == '已结束'">已结束</div>
          <div style="color:green" v-else-if="timex5 == '未开始'">未开始</div>
          <van-count-down v-else :time="timex5" @finish="changefinish(5)" />
        </div>
      </div>
      <div class="newpepele-btns">
        <div
          class="newpepele-btns-item"
          :class="datalist.list[4][0] == 1 ? 'onchangetab' : ''"
        >
          本日完成1次签到
        </div>
        <div
          class="newpepele-btns-item"
          :class="datalist.list[4][1] == 1 ? 'onchangetab' : ''"
        >
          在本日完成1笔存款
        </div>
      </div>
      <div class="newpepele-regulation">
        <div class="regulation-title">任务拟定:</div>
        <div class="regulation-content">
          <p>1.本日完成1次签到</p>
          <p>2.在本日完成1笔存款</p>
        </div>
        <div class="regulation-title">任务奖励:</div>
        <div class="regulation-content">
          完成本日任务送 <span style="color:#2d70fd">38</span> 彩金
        </div>
      </div>
    </div>

    <!-- 第六天 -->
    <div class="newpepele-item" id="page6" v-if="datalist.list">
      <div class="newpepele-title">
        <img
          src="	https://sabbont.gz.bcebos.com/pc/img/discount/conditionact1.png"
        />
        第六天
      </div>

      <div class="newpepele-img">
        <div class="newpepele-box">
          <img :src="`${imgUrl}pcbb/newperson6.png`" />
          <img
            class="circle-btn circle-btn3"
            :class="changrebagindex == 6 ? 'rotateing' : ''"
            @click="changetab(6)"
            :src="`${imgUrl}pcbb/circle-btn.png`"
          />
          <div class="circle-money circle-money6">
            38彩金
          </div>
          <div
            class="circle-text circle-text3"
            @click="changetab(6)"
            v-if="
              datalist.list[5][0] == 1 &&
                datalist.list[5][1] == 1 &&
                timex6 != '已结束' &&
                timex6 != '未开始' &&
                datalist.ztlist[5] == 0
            "
          >
            可领取
          </div>
          <div class="circle-text circle-text3" v-else-if="timex6 == '未开始'">
            未开始
          </div>
          <div
            class="circle-text circle-text3"
            v-else-if="datalist.ztlist[5] == 1"
          >
            已领取
          </div>
          <div class="circle-text circle-text3" v-else-if="timex6 == '已结束'">
            已结束
          </div>
          <div class="circle-text circle-text3" v-else>
            任务未完成
          </div>
        </div>
        <div class="time">
          剩余时间:
          <div style="color:red" v-if="timex6 == '已结束'">已结束</div>
          <div style="color:green" v-else-if="timex6 == '未开始'">未开始</div>
          <van-count-down v-else :time="timex6" @finish="changefinish(6)" />
        </div>
      </div>
      <div class="newpepele-btns">
        <div
          class="newpepele-btns-item"
          :class="datalist.list[5][0] == 1 ? 'onchangetab' : ''"
        >
          <邀请好友>活动邀请1名好友
        </div>
        <div
          class="newpepele-btns-item"
          :class="datalist.list[5][1] == 1 ? 'onchangetab' : ''"
        >
          参与1笔≥100元任意游戏投注
        </div>
      </div>
      <div class="newpepele-regulation">
        <div class="regulation-title">任务拟定:</div>
        <div class="regulation-content">
          <p>1.<邀请好友>活动邀请1名好友,并获得邀请奖励</p>
          <p>2.参与1笔≥100元任意游戏投注</p>
        </div>
        <div class="regulation-title">任务奖励:</div>
        <div class="regulation-content">
          完成本日任务送 <span style="color:#2d70fd">38</span> 彩金
        </div>
      </div>
    </div>

    <!-- 第七天 -->
    <div class="newpepele-item" id="page7" v-if="datalist.list">
      <div class="newpepele-title">
        <img
          src="	https://sabbont.gz.bcebos.com/pc/img/discount/conditionact1.png"
        />
        第七天
      </div>

      <div class="newpepele-img">
        <div class="newpepele-box">
          <img :src="`${imgUrl}pcbb/newperson7.png`" />
          <img
            class="circle-btn circle-btn6"
            :src="`${imgUrl}pcbb/circle-btn.png`"
            :class="changrebagindex == 7 ? 'rotateing' : ''"
            @click="changetab(7)"
          />
          <div class="circle-money circle-money7">
            38彩金
          </div>

          <div
            class="circle-text circle-text3"
            @click="changetab(7)"
            v-if="
              datalist.list[6][0] == 1 &&
                timex7 != '已结束' &&
                timex7 != '未开始' &&
                datalist.ztlist[6] == 0
            "
          >
            可领取
          </div>
          <div class="circle-text circle-text3" v-else-if="timex7 == '未开始'">
            未开始
          </div>
          <div
            class="circle-text circle-text3"
            v-else-if="datalist.ztlist[6] == 1"
          >
            已领取
          </div>
          <div class="circle-text circle-text3" v-else-if="timex7 == '已结束'">
            已结束
          </div>
          <div class="circle-text circle-text3" v-else>
            任务未完成
          </div>
        </div>
        <div class="time">
          剩余时间:
          <div style="color:red" v-if="timex7 == '已结束'">已结束</div>
          <div style="color:green" v-else-if="timex7 == '未开始'">未开始</div>
          <van-count-down v-else :time="timex7" @finish="changefinish(7)" />
        </div>
      </div>
      <div class="newpepele-btns">
        <div
          class="newpepele-btns-item"
          :class="datalist.list[6][0] == 1 ? 'onchangetab' : ''"
        >
          在七日内参与过任意1次体育保单
        </div>
      </div>
      <div style="font-size:12px;color:#999;text-align: center;">
        注:此任务可提前完成，但奖励只能在第七日领取
      </div>

      <div class="newpepele-regulation">
        <div class="regulation-title">任务拟定:</div>
        <div class="regulation-content">
          1.在七日内参与过任意1次体育保单
        </div>
        <div class="regulation-title">任务奖励:</div>
        <div class="regulation-content">
          完成本日任务送 <span style="color:#2d70fd">38</span> 彩金
        </div>
      </div>
    </div>

    <!-- 最后奖励 -->
    <div class="newpepele-item" id="page8" v-if="datalist.list">
      <div class="newpepele-title">
        <img
          src="	https://sabbont.gz.bcebos.com/pc/img/discount/conditionact1.png"
        />
        最后奖励
      </div>

      <div class="newpepele-img" style="margin-top:20px">
        <div class="newpepele-box">
          <img :src="`${imgUrl}pcbb/newperson8.png`" />
          <img
            class="circle-btn circle-btn4"
            :src="`${imgUrl}pcbb/circle-btn.png`"
            :class="changrebagindex == 8 ? 'rotateing' : ''"
            @click="changetab(8)"
            style="width:30px;height:30px"
          />
          <div class="circle-money circle-money4" style="font-size: 14px;">
            288彩金
          </div>
          <div
            class="circle-text circle-text4"
            @click="changetab(8)"
            v-if="datalist.zbouns_zt == 1 && datalist.ztlist[7] == 0"
          >
            可领取
          </div>
          <div
            class="circle-text circle-text4"
            v-else-if="datalist.ztlist[7] == 1"
          >
            已领取
          </div>
          <div
            class="circle-text circle-text4"
            v-else-if="datalist.zbouns_zt == 0 && timex8 != '已结束'"
          >
            未开启
          </div>
          <div class="circle-text circle-text4" v-else>
            已结束
          </div>
        </div>
        <div class="time">
          剩余时间:
          <!-- <div style="color:green" v-if="timeout == 1">已开启</div> -->

          <div style="color:green" v-if="timeout == 0 && timex8 != '已结束'">
            未开启
          </div>
          <van-count-down
            v-else-if="timeout == 1 && timex8 != '已结束'"
            :time="timex8"
            @finish="changefinish(8)"
          />
          <div style="color:red" v-else>已结束</div>
        </div>
      </div>
      <div class="newpepele-btns">
        <div
          class="newpepele-btns-item"
          :class="datalist.zbouns_zt == 1 ? 'onchangetab' : ''"
        >
          领取288彩金+10%七日内负盈利，负盈利最高888彩金
        </div>
      </div>
      <div class="newpepele-regulation">
        <div class="regulation-title">任务拟定:</div>
        <div class="regulation-content">
          新手任务结束后次日可领取.
        </div>
        <div class="regulation-title">任务奖励:</div>
        <div class="regulation-content">
          可领取288彩金+10%七日内负盈利，负盈利最高
          <span style="color:#2d70fd">888</span> 彩金。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getdata } from '../network/api'
import { Toast } from 'vant'

export default {
  data() {
    return {
      changrebagindex: 0,
      timeout: 0,
      datalist: {},
      timex1: '未开始',
      timex2: '未开始',
      timex3: '未开始',
      timex4: '未开始',
      timex5: '未开始',
      timex6: '未开始',
      timex7: '未开始',
      timex8: '未开始',
    }
  },
  methods: {
    getdata() {
      let data = '?type=newevent'
      getdata(data).then((res) => {
        if (res.status_code === 200) {
          this.datalist = res.message
          this.timeout = res.message.zbouns_zt
          this.msgFrom()
          if (this.datalist.timelist) {
            this.getdatatime(
              this.datalist.timelist[0],
              'timex1',
              this.datalist.ntime
            )
            this.getdatatime(
              this.datalist.timelist[1],
              'timex2',
              this.datalist.ntime
            )
            this.getdatatime(
              this.datalist.timelist[2],
              'timex3',
              this.datalist.ntime
            )
            this.getdatatime(
              this.datalist.timelist[3],
              'timex4',
              this.datalist.ntime
            )
            this.getdatatime(
              this.datalist.timelist[4],
              'timex5',
              this.datalist.ntime
            )
            this.getdatatime(
              this.datalist.timelist[5],
              'timex6',
              this.datalist.ntime
            )
            this.getdatatime(
              this.datalist.timelist[6],
              'timex7',
              this.datalist.ntime
            )
            this.getdatatime(
              this.datalist.timelist[7],
              'timex8',
              this.datalist.ntime
            )
          }
        }
      })
    },
    changefinish(index) {
      switch (index) {
        case 1:
          _this.timex1 = '已结束'
          break
        case 2:
          _this.timex2 = '已结束'
          break
        case 3:
          _this.timex3 = '已结束'
          break
        case 4:
          _this.timex4 = '已结束'
          break
        case 5:
          _this.timex5 = '已结束'
          break
        case 6:
          _this.timex6 = '已结束'
          break
        case 7:
          _this.timex7 = '已结束'
          break
        case 8:
          _this.timex8 = '已结束'
          break
      }
    },
    getdatatime(times, index, ntime) {
      let _this = this
      var date = new Date(ntime.replace(/\-/g, '/'))
      var now = date.getTime()
      var t2 = new Date(times.replace(/\-/g, '/')) * 1
      var time = t2 - now
      switch (index) {
        case 'timex1':
          if (time <= 0) {
            _this.timex1 = '已结束'
          } else if (time > 86400000) {
            _this.timex1 = '未开始'
          } else {
            _this.timex1 = Number(time)
          }
          break
        case 'timex2':
          if (time <= 0) {
            _this.timex2 = '已结束'
          } else if (time > 86400000) {
            _this.timex2 = '未开始'
          } else {
            _this.timex2 = Number(time)
          }

          break
        case 'timex3':
          if (time <= 0) {
            _this.timex3 = '已结束'
          } else if (time > 86400000) {
            _this.timex3 = '未开始'
          } else {
            _this.timex3 = Number(time)
          }

          break
        case 'timex4':
          if (time <= 0) {
            _this.timex4 = '已结束'
          } else if (time > 86400000) {
            _this.timex4 = '未开始'
          } else {
            _this.timex4 = Number(time)
          }

          break
        case 'timex5':
          if (time <= 0) {
            _this.timex5 = '已结束'
          } else if (time > 86400000) {
            _this.timex5 = '未开始'
          } else {
            _this.timex5 = Number(time)
          }

          break
        case 'timex6':
          if (time <= 0) {
            _this.timex6 = '已结束'
          } else if (time > 86400000) {
            _this.timex6 = '未开始'
          } else {
            _this.timex6 = Number(time)
          }

          break
        case 'timex7':
          if (time <= 0) {
            _this.timex7 = '已结束'
          } else if (time > 86400000) {
            _this.timex7 = '未开始'
          } else {
            _this.timex7 = Number(time)
          }
          break
        case 'timex8':
          if (time <= 0) {
            _this.timex8 = '已结束'
          } else if (time > 86400000) {
            _this.timex8 = '未开始'
          } else {
            _this.timex8 = Number(time)
          }
          break
      }
    },
    Submit(key) {
      if (this.datalist.ret == 0) return

      if (key == 1) {
        if (this.timex1 == '已结束') return
      }
      if (key == 2) {
        if (this.timex2 == '已结束') return
      }
      if (key == 3) {
        if (this.timex3 == '已结束') return
      }
      if (key == 4) {
        if (this.timex4 == '已结束') return
      }
      if (key == 5) {
        if (this.timex5 == '已结束') return
      }
      if (key == 6) {
        if (this.timex6 == '已结束') return
      }
      if (key == 7) {
        if (this.timex7 == '已结束') return
      }
      var PageId = document.querySelector('#page' + key)

      window.scrollTo({
        top: PageId.offsetTop - 100,

        behavior: 'smooth',
      })
    },
    changetab(index) {
      if (this.changrebagindex == index) return
      if (this.datalist.ret == 0) {
        Toast('活动还未激活')
        return
      }
      if (index == 8) {
        if (this.timeout == 0) {
          Toast('活动暂未开启,还不能领取')
          return
        } else if (this.timex8 == '已结束') {
          Toast('活动已结束,无法领取，如有疑问请联系客服')
          return
        }
      } else if (index == 1) {
        if (this.timex1 == '已结束') {
          Toast('活动已结束,无法领取，如有疑问请联系客服')
          return
        } else if (
          this.datalist.list[0][0] == 0 ||
          this.datalist.list[0][1] == 0 ||
          this.datalist.list[0][2] == 0
        ) {
          Toast(
            '活动暂未完成,请完成相关任务后再来领取,如已完成可能存在延迟,重新进入再次领取'
          )
          return
        }
      } else if (index == 2) {
        if (this.timex2 == '已结束') {
          Toast('活动已结束,无法领取，如有疑问请联系客服')
          return
        } else if (
          this.datalist.list[1][0] == 0 ||
          this.datalist.list[1][1] == 0 ||
          this.datalist.list[1][2] == 0
        ) {
          Toast(
            '活动暂未完成,请完成相关任务后再来领取,如已完成可能存在延迟,重新进入再次领取'
          )
          return
        }
      } else if (index == 3) {
        if (this.timex3 == '已结束') {
          Toast('活动已结束,无法领取，如有疑问请联系客服')
          return
        } else if (
          this.datalist.list[2][0] == 0 ||
          this.datalist.list[2][1] == 0 ||
          this.datalist.list[2][2] == 0
        ) {
          Toast(
            '活动暂未完成,请完成相关任务后再来领取,如已完成可能存在延迟,重新进入再次领取'
          )
          return
        }
      } else if (index == 4) {
        if (this.timex4 == '已结束') {
          Toast('活动已结束,无法领取，如有疑问请联系客服')
          return
        } else if (
          this.datalist.list[3][0] == 0 ||
          this.datalist.list[3][1] == 0
        ) {
          Toast(
            '活动暂未完成,请完成相关任务后再来领取,如已完成可能存在延迟,重新进入再次领取'
          )
          return
        }
      } else if (index == 5) {
        if (this.timex5 == '已结束') {
          Toast('活动已结束,无法领取，如有疑问请联系客服')
          return
        } else if (
          this.datalist.list[4][0] == 0 ||
          this.datalist.list[4][1] == 0
        ) {
          Toast(
            '活动暂未完成,请完成相关任务后再来领取,如已完成可能存在延迟,重新进入再次领取'
          )
          return
        }
      } else if (index == 6) {
        if (this.timex6 == '已结束') {
          Toast('活动已结束,无法领取，如有疑问请联系客服')
          return
        } else if (
          this.datalist.list[5][0] == 0 ||
          this.datalist.list[5][1] == 0
        ) {
          Toast(
            '活动暂未完成,请完成相关任务后再来领取,如已完成可能存在延迟,重新进入再次领取'
          )
          return
        }
      } else if (index == 7) {
        if (this.timex7 == '已结束') {
          Toast('活动已结束,无法领取，如有疑问请联系客服')
          return
        } else if (this.datalist.list[6][0] == 0) {
          Toast(
            '活动暂未完成,请完成相关任务后再来领取,如已完成可能存在延迟,重新进入再次领取'
          )
          return
        }
      }
      this.changrebagindex = index

      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          // Toast('该活动仅在app开发,请下载APP')
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'vipActapplyobject',
                actobject: {
                  act: 'newevent',
                },
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {
          // uni.postMessage({
          //   data: {
          //     urlApply: 'vipActapplyobject',
          //     actobject: {
          //       act: 'newevent',
          //     },
          //   }
          // })
          // 申请
          uni.postMessage({
            data: {
              urlApply: 'getnewpeople',
            },
          })
        }
      })
    },
    msgFrom() {
      let that = this
      window.neweventUniapp = function(arg) {
        if (arg == 1) {
          that.changrebagindex = 0
        }
      }
    },
    gotoback() {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true',
            },
          })
        })
      }
    },
  },
  mounted() {
    let that = this
    // this.getdatatime()
    this.getdata()
    if (window.localStorage.getItem('opentype') == 'h5') {
      window.addEventListener('message', function (e) {
        // 数据返回
        if (e.data.act == 'newevent') {
          if (e.data.status == 1) {
            that.changrebagindex = 0
          }
        }
      })
    }
  },
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

body {
  background-color: #f7f8fa;
}

.changebox {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  background: var(--w-bgcolor);
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  margin-bottom: 0;
  padding-bottom: 0;
  box-shadow: 0px 1px 6px 0px #d7dae2;

  .changebox-item {
    margin-right: 4.95%;
    margin-bottom: 10px;
    width: 30%;
    height: 40px;
    line-height: 40px;
    background: linear-gradient(0deg, #2a6afd, #63c6ff);
    border-radius: 10px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.onchangetab {
  background: linear-gradient(0deg, #95b5ff, #9fdcff) !important;
}

.newpepele-item {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 1px 6px 0px #d7dae2;
  position: relative;
  margin-top: 20px;
  background: var(--w-bgcolor);

  .newpepele-title {
    width: 129px;
    height: 40px;
    line-height: 40px;
    position: absolute;
    top: -6px;
    left: 50%;
    text-align: center;
    color: #ffffff;
    transform: translateX(-50%);
    z-index: 10;

    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 129px;
      height: 40px;
      z-index: -1;
    }
  }

  .newpepele-img {
    width: 100%;

    img {
      width: 100%;
    }

    .newpepele-box {
      position: relative;
      min-height: 130px;
    }

    .time {
      font-size: 15px !important;
      font-family: PingFang SC;
      font-weight: 400;
      color: #418beb;
      line-height: 23px;
      text-align: center;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .circle-btn {
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      cursor: pointer;
      animation: scalecrirle 1s linear infinite;
    }

    .circle-btn1 {
      top: 76px;
      left: 150px;
    }

    .circle-btn2 {
      top: 76px;
      left: 150px;
    }

    .circle-btn3 {
      top: 76px;
      left: 147px;
    }

    .circle-btn5 {
      top: 72px;
      left: 140px;
    }

    .circle-btn6 {
      top: 78px;
      left: 149px;
    }

    .circle-btn4 {
      top: 50px;
      left: 160px;
    }

    .circle-money {
      color: #ffebc9;
      position: absolute;
      font-family: PingFang SC;
      font-weight: 400;
      text-shadow: 2px 2px 3px #841414;
    }

    .circle-money1 {
      bottom: 95px;
      left: 149px;
    }

    .circle-text1 {
      bottom: 66px;
      left: 130px;
    }

    .circle-money2 {
      bottom: 87px;
      left: 139px;
    }

    .circle-text2 {
      bottom: 58px;
      left: 119px;
    }

    .circle-money3 {
      bottom: 93px;
      left: 151px;
    }

    .circle-text3 {
      bottom: 60px;
      left: 130px;
    }

    .circle-money5 {
      bottom: 90px;
      left: 148px;
    }

    .circle-money6 {
      bottom: 93px;
      left: 148px;
    }

    .circle-money7 {
      bottom: 93px;
      left: 150px;
    }

    .circle-text5 {
      bottom: 60px;
      left: 127px;
    }

    .circle-money4 {
      bottom: 59px;
      left: 50%;
      transform: translateX(-32%);
    }

    .circle-text4 {
      bottom: 35px;
      left: 50%;
      transform: translateX(-32%);
      width: 50px !important;
      height: 16px !important;
      line-height: 16px !important;
    }

    .circle-text {
      position: absolute;
      width: 90px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      color: #5c2a12;
      background: #fffadc;
      box-shadow: 2px 2px 3px 0px #841414;
      border-radius: 12px;
      text-align: center;
    }
  }

  .newpepele-btns {
    display: flex;
    align-content: center;
    justify-content: space-around;

    .newpepele-btns-item {
      margin: 10px 2px;
      height: 38px;
      width: 100%;
      background: linear-gradient(0deg, #2a6afd, #63c6ff);
      border-radius: 10px;
      font-size: 12px;
      color: #fff;
      font-weight: 400;
      line-height: 38px;
      text-align: center;
    }
  }

  .newpepele-regulation {
    .regulation-title {
      font-family: PingFang SC;
      font-weight: 400;
      color: #418beb;
      line-height: 23px;
      font-size: 14px;
    }

    .regulation-content {
      font-family: PingFang SC;
      font-weight: 400;
      margin-bottom: 5px;
      color: #303030;
      line-height: 23px;
      font-size: 14px;
    }
  }
}

.rotateing {
  animation: loading 1s linear infinite !important;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes scalecrirle {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

.van-count-down {
  font-size: 15px;
  color: #418beb;
  margin-left: 5px;
}
</style>
